const basePath = '/client';
const assetsPath = '/client_assets';
const names = {
	BasePath: basePath,
	AssetsBasePath: assetsPath,
	AuthorizedDefaultRoute: `/client/account/details`,
	UnAuthorizedDefaultRoute: `/login`,
	Cookies: `/cookies`,

	Login: `/login`,
	SSOLogin: `/login/:provider`,
	LoginEmailVerification: `/login/email-change-verification`,
	ForgotPassword: `/login/reminder`,
	ResetPassword: `/login/reset`,
	SignUp: '/register',
	ClaimLicenses: '/client/claim-licenses',
	Forbidden: `${basePath}/forbidden`,
	NotFound: `${basePath}/404`,

	Store: '/store',
	Tools: '/select/',
	Home: '/',

	BaseAccount: `/client/account`,
	Account: `/client/account/details`,
	ChangePassword: `/client/account/password`,
	AddressBook: `/client/account/addresses`,
	CapaProListing: `/client/account/capapro`,

	Orders: `/client/account/orders`,
	OrderDetails: `/client/account/orders/order`,
	OrderPrintReceipt: `/client/pdf/order`,
	OrderAutoDownload: `/client/download/order/:id`,

	VideoList: `/client/video`,
	VideoDetails: `/client/video/details`,

	Assessments: `/client`,
	IndividualAssessment: `/client/assessment/setup/individual`,
	TeamAssessmentSetup: `/client/assessment/setup/team`,
	Licenses: `/client/account/licenses`,
	Help: `/client/help`,

	AssessmentDetails: `/client/assessment`,

	TeamAssessmentConsole: `/client`,
	MeetingAdvantage: `/meetings/dashboard`,
	CapaPro: `/capapro`,
	Cart: `/shopping-cart-view`,

	OnlineTeamAssessment: `/product/online-team-assessment/`,

	Survey: `/client/surveys`,
	SurveyTake: '/client/surveys/take',
	SurveyPdf: '/client/surveys/pdf',

	ItpAssessment: `/client/itp_assessment`,
	ItpAssessmentTake: '/client/itp_assessment/take',
	ItpAssessmentPdf: '/client/itp_assessment/pdf',

	FindAssessment: `/assessment/find`,
	FindAssessmentList: '/assessment/find/list',
	TakeAssessment: `/assessment/take`,
	SendAssessmentReminder: `/assessment/send-reminder`,
	AssessmentReport: `/assessment/report`,
	AssessmentProgressReport: `/assessment/progress-report`,
	AssessmentGenerateReport: `/assessment/generate-report`,

	TermsService: '/terms-of-service',
	Privacy: '/privacy',
	DataPrivacy: '/data-privacy',
	MicrosoftLogout: '/login/MICROSOFT/logout',

	PodcastPdf: '/client/pdf/podcast',
	ItpAssessments: '/itp',
	ItpAutomaticSetup: '/itp/setup/automatic',
	ItpTake: '/itp/take'
};

export const PublicRouteNames = [
	names.Login,
	names.LoginEmailVerification,
	names.SignUp,
	names.ClaimLicenses,
	names.ForgotPassword,
	names.ResetPassword,
	names.FindAssessment,
	names.FindAssessmentList,
	names.TakeAssessment,
	names.SendAssessmentReminder,
	names.AssessmentReport,
	names.AssessmentProgressReport,
	names.AssessmentGenerateReport,
	names.Survey,
	names.SurveyTake,
	names.SurveyPdf,
	names.ItpAssessment,
	names.ItpAssessmentTake,
	names.ItpAssessmentPdf,
	names.PodcastPdf,
];

export default names;
