export enum ITP_ASSESSMENT_TEAM_ROSTER_STATUS_ENUM {
  PENDING = "PENDING",
  IN_PROGRESS_INITIAL = "IN_PROGRESS_INITIAL",
  COMPLETED_ASSESSMENT = "COMPLETED_ASSESSMENT",
  COMPLETED_COACHING1 = "COMPLETED_COACHING1",
  COMPLETED_COACHING2 = "COMPLETED_COACHING2",
  COMPLETED_COACHING3 = "COMPLETED_COACHING3",
  IN_PROGRESS_FINAL = "IN_PROGRESS_FINAL",
  COMPLETED = "COMPLETED",
  STOPPED = "STOPPED",
}

export enum ITP_ASSESSMENT_TEAM_ROSTER_SCHEDULE_STATUS_ENUM {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export enum ITP_ASSESSMENT_TEAM_ROSTER_RESPONSE_TYPE_ENUM {
  ASSESSMENT = "ASSESSMENT",
  PROGRESS = "PROGRESS",
}

export enum ITP_ASSESSMENT_TEAM_ROSTER_SCHEDULE_TYPE_ENUM {
  INITIAL_EMAIL_REMINDER_1 = "INITIAL_EMAIL_REMINDER_1",
  INITIAL_EMAIL_REMINDER_2 = "INITIAL_EMAIL_REMINDER_2",
  COMPLETE_COACHING_EMAIL_1 = "COMPLETE_COACHING_EMAIL_1",
  COMPLETE_COACHING_EMAIL_2 = "COMPLETE_COACHING_EMAIL_2",
  COMPLETE_COACHING_EMAIL_3 = "COMPLETE_COACHING_EMAIL_3",
  COMPLETE_PROGRESS = "COMPLETE_PROGRESS",
}
