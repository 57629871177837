import React from 'react';
import { AppButton, AppLoading, AppNotice } from '../../components/App';
import { AccountLayout } from '../../components/Layout';
import {
	AddressForm,
	AddressDeleteModal,
	AddressEmpty,
	AddressInformation,
	AddressSetAsPrimaryModal,
} from '../../components/Address';
import AddressService from '../../services/AddressService';

const addressInitialState = {
	loading: false,
	primaryAddress: {},
	alternateAddresses: [],
	addresses: [],
	setAsPrimaryLoading: false,
	addLoading: false,
	deleteLoading: false,
	updateLoading: false,
};

const AddressBook = () => {
	const [selectedAddressId, setSelectedAddressId] = React.useState(-1);
	const [selectedAddress, setSelectedAddress] = React.useState({});
	const [openPrimaryAddressModal, setOpenPrimaryAddressModal] = React.useState(false);
	const [openAddressFormModal, setOpenAddressFormModal] = React.useState(false);
	const [openDeleteAddressModal, setOpenDeleteAddressModal] = React.useState(false);
	const [showNotice, setShowNotice] = React.useState(true);
	const [selectedAddressIdToDelete, setSelectedAddressIdToDelete] = React.useState(-1);
	const [addressState, setAddressState] = React.useState(addressInitialState);
	const {addresses, primaryAddress, alternateAddresses, loading} = addressState;
	const _getAddresses = React.useCallback(() => {
		AddressService.getAddresses().then((response) => {
			const { addresses, primary = {}, alternates = [] } = response;
			setAddressState({
				...addressState,
				addresses,
				primaryAddress: primary,
				alternateAddresses: alternates,
			});
		});
	}, []);

	React.useEffect(() => {
		_getAddresses();
	}, [_getAddresses]);

	const editAddress = (address) => {
		setSelectedAddress(address);
		setOpenAddressFormModal(true);
	};

	const deleteAddress = (address_book_id) => {
		setSelectedAddressIdToDelete(address_book_id);
		setOpenDeleteAddressModal(true);
	};

	const setAddressAsPrimary = (address_book_id) => {
		setSelectedAddressId(address_book_id);
		setOpenPrimaryAddressModal(true);
	};
	return (
		<AccountLayout>
			{showNotice && addresses && addresses.length >= 20 && (
				<AppNotice
					loading={loading}
					containerClass={'ttg-account-section'}
					title={'You have reached the maximum amount of addresses to add in Address Book'}
					description={`You currently have <b>20</b> out of a total possible of <b>20</b> addresses in your Address Book. You can always remove your current address in order to add new ones.`}
					actions={[
						{
							id: 'ttg-account-dissmis-action',
							text: 'Dismiss',
							action: () => {
								setShowNotice(false);
							},
						},
					]}
				/>
			)}
			<div className={'ttg-account-section'}>
				<div id={'ttg-addressbook-section'}>
					<div className='ttg-addressbook-header'>
						<div className='ttg-addressbook-header-info'>
							<h1>Address Book</h1>
							<p>Your Address Book is used to store up to twenty addresses for your account.</p>
						</div>
						<AppButton
							id={'ttg-addressbook-add-btn'}
							text={'+ Add an Address'}
							onClick={() => {
								setOpenAddressFormModal(true);
								setSelectedAddress({
									address_book_id: null,
									entry_firstname: '',
									entry_lastname: '',
									entry_company: '',
									entry_street_address: '',
									entry_city: '',
									entry_postcode: '',
									country_code: 'US',
									zone_code: '',
									entry_phone: '',
								});
							}}
							disabled={addresses && addresses.length >= 20}
						/>
					</div>
					{loading ? (
						<AppLoading height={'h-full'} />
					) : (
						<div>
							{primaryAddress && Object.keys(primaryAddress).length !== 0 && (
								<div className='ttg-addressbook-primary' id={'ttg-addressbook-primary-id'}>
									<p className='ttg-addressbook-label'>Primary Address</p>
									<AddressInformation
										address={primaryAddress}
										isDefault={true}
										onEdit={editAddress}
									/>
								</div>
							)}
							{alternateAddresses && alternateAddresses.length !== 0 && (
								<div className='ttg-addressbook-alternate' id={'ttg-addressbook-alternate-id'}>
									<p className='ttg-addressbook-label'>Alternate Address</p>
									<div className='flex-1'>
										{alternateAddresses.map((address, index) => (
											<AddressInformation
												address={address}
												key={index}
												onEdit={editAddress}
												onDelete={deleteAddress}
												onSetAsPrimary={setAddressAsPrimary}
												checked={address.address_book_id === selectedAddressId}
											/>
										))}
									</div>
								</div>
							)}
							{Object.keys(primaryAddress).length === 0 && alternateAddresses.length === 0 && (
								<AddressEmpty />
							)}
						</div>
					)}
				</div>
			</div>
			<AddressSetAsPrimaryModal
				isOpen={openPrimaryAddressModal}
				closeModal={() => {
					setOpenPrimaryAddressModal(false);
					setSelectedAddressId(-1);
				}}
				address_id={selectedAddressId}
				setAddresses={setAddressState}
				addressesState={addressState}
			/>
			<AddressForm
				isOpen={openAddressFormModal}
				closeModal={() => {
					setOpenAddressFormModal(false);
					setSelectedAddressId(-1);
					setSelectedAddress({ address_book_id: selectedAddress.address_book_id });
				}}
				noAddresses={addresses ? addresses.length : 0}
				address={selectedAddress}
				setAddresses={setAddressState}
				addressesState={addressState}
			/>
			<AddressDeleteModal
				isOpen={openDeleteAddressModal}
				closeModal={() => {
					setOpenDeleteAddressModal(false);
					setSelectedAddressIdToDelete(-1);
				}}
				address_id={selectedAddressIdToDelete}
				setAddresses={setAddressState}
				addressesState={addressState}
			/>
		</AccountLayout>
	);
};

export default AddressBook;
